<template>
  <!-- udesk客户手机号识别 -->
  <div class="page_">
    <div class="top_">
      <img src="../../assets/logo.png" alt="" /> |
      中间带（北京）技术服务有限公司
    </div>
    <div class="con_">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" :tab="'通话列表'">
          <a-form>
            <a-row>
              <a-col :span="4">
                <span>手机号：</span>
                <a-input
                  placholder="请输入手机号"
                  style="width:60%"
                  v-model.trim="form.callNumber"
                ></a-input>
              </a-col>
              <a-col :span="4">
                <span>Name：</span>
                <a-input
                  placholder="请输入用户昵称"
                  style="width:60%"
                  v-model.trim="form.userName"
                ></a-input>
              </a-col>
              <a-col :span="4">
                <span>结果：</span>
                <a-select
                  placholder="请选择呼叫结果"
                  style="width: 60%;border: 1px solid #d9d9d9!important;border-radius:2px;"
                  v-model="form.callResult"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in resultList"
                    :key="index"
                    >{{ item.text }}</a-select-option
                  >
                </a-select>
              </a-col>
              <a-col :span="4">
                <span>状态：</span>
                <a-select
                  placholder="请选择状态"
                  style="width: 60%;border: 1px solid #d9d9d9!important;border-radius:2px;"
                  v-model="form.status"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in statusList"
                    :key="index"
                    >{{ item.text }}</a-select-option
                  >
                </a-select>
              </a-col>
              <a-col :span="4">
                <span>推送：</span>
                <a-select
                  placholder="请选择状态"
                  style="width: 60%;border: 1px solid #d9d9d9!important;border-radius:2px;"
                  v-model="form.isPush"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in pushList"
                    :key="index"
                    >{{ item.text }}</a-select-option
                  >
                </a-select>
              </a-col>
              <a-col :span="4">
                <a-button type="primary" @click="fetchData">搜索</a-button>
                <a-button class="top-btn" type="primary" @click="exportData"
                  >导出</a-button
                >
              </a-col>
            </a-row>
          </a-form>
          <p class="title_">20分钟内未通话列表</p>
          <a-table
            :columns="columns"
            :data-source="InfoList"
            size="small"
            :pagination="pagination"
            @change="pageChange"
          >
            <template slot="time" slot-scope="record">
              <span>{{ record.callStartAt | filterTime }}</span>
            </template>
            <template slot="status" slot-scope="record">
              <span>{{ record.status | filterStatus }}</span>
            </template>
            <template slot="isPush" slot-scope="record">
              <span>{{ record.isPush | filterPush }}</span>
            </template>
            <template slot="do" slot-scope="record">
              <a-switch
                @change="onChange(record)"
                :checked="record.status == '2' ? true : false"
              />
              是否需要回拨
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="'禁止提醒列表'">
          <a-form>
            <a-row style="margin-bottom:20px;">
              <a-col :span="6">
                <span>手机号：</span>
                <a-input
                  placholder="请输入手机号"
                  style="width:60%"
                  v-model="newCallNumber"
                ></a-input>
              </a-col>
              <a-col :span="6">
                <a-button type="primary" @click="addList"
                  >添加进禁止提醒列表</a-button
                >
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6">
                <span>手机号：</span>
                <a-input
                  placholder="请输入手机号"
                  style="width:60%"
                  v-model="callNumber"
                ></a-input>
              </a-col>
              <a-col :span="6">
                <a-button type="primary" @click="fetchData1">搜索</a-button>
              </a-col>
            </a-row>
          </a-form>
          <p class="title_">禁止提醒列表</p>
          <a-table
            :columns="columns1"
            :data-source="InfoList1"
            size="small"
            :pagination="pagination1"
            @change="pageChange1"
          >
            <template slot="do" slot-scope="record">
              <a-switch
                @change="onChange1(record)"
                :checked="record.status == '0' ? false : true"
              />
              是否禁止提醒
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "序号",
    dataIndex: "id"
  },
  {
    title: "姓名",
    dataIndex: "userName"
  },
  {
    title: "手机号",
    dataIndex: "callNumber"
  },
  {
    title: "结果",
    dataIndex: "callResult"
  },
  {
    title: "拨打时间",
    scopedSlots: { customRender: "time" }
  },
  {
    title: "状态",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "是否推送",
    scopedSlots: { customRender: "isPush" }
  },
  {
    title: "操作",
    scopedSlots: { customRender: "do" }
  }
];
const columns1 = [
  {
    title: "序号",
    dataIndex: "id"
  },
  {
    title: "手机号",
    dataIndex: "callNumber"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "do" }
  }
];
const statusList = [
  { value: "", text: "请选择" },
  { value: "1", text: "不需要处理" },
  { value: "2", text: "未处理" },
  { value: "3", text: "已经处理" }
];
const pushList = [
  { text: "全部", value: "" },
  { text: "是", value: "Y" },
  { text: "否", value: "N" }
];
export default {
  data() {
    return {
      phone: "",
      columns,
      columns1,
      InfoList: [],
      InfoList1: [
        {
          phone: 1111,
          key: "167",
          active: false
        }
      ],
      pagination: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
        current: 1
      },
      pagination1: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
        current: 1
      },
      form: {
        callNumber: "",
        userName: "",
        callResult: "",
        status: "",
        isPush: ""
      },
      resultList: [
        {
          value: "",
          text: "请选择"
        },
        {
          value: "0",
          text: "客户未接"
        },
        {
          value: "1",
          text: "客户接听"
        },
        {
          value: "2",
          text: "客服未接"
        },
        {
          value: "3",
          text: "客服拒接"
        },
        {
          value: "4",
          text: "客服接听"
        },
        {
          value: "5",
          text: "未选择队列"
        }
      ],
      statusList,
      pushList,
      callNumber: "",
      newCallNumber: ""
    };
  },
  created() {
    this.fetchData();
    this.fetchData1();
  },
  filters: {
    filterTime: val => {
      if (!val) return "";
      if (val) {
        let str = val.substring(0, 10) + " " + val.substring(11, 19);
        return str;
      }
    },
    filterStatus: val => {
      if (!val) {
        return "";
      }
      const item = statusList.find(item => {
        return item.value == val;
      });
      return item.text;
    },
    filterPush: val => {
      if (!val) {
        return "";
      }
      const item = pushList.find(item => {
        return item.value == val;
      });
      return item.text;
    }
  },
  methods: {
    fetchData() {
      let postData = {
        ...this.form
      };
      if (this.form.callResult != "") {
        postData.callResult = this.resultList.filter(
          item => item.value == this.form.callResult
        )[0].text;
      }
      this.$apis
        .getUdeskCallLogs(
          {
            pageNum: this.pagination.current,
            pageSize: this.pagination.pageSize
          },
          postData
        )
        .then(res => res.data)
        .then(data => {
          this.InfoList = data.content.list;
          this.pagination.total = data.content.total;
        });
    },
    fetchData1() {
      this.$apis
        .getUdeskDisableCalls(
          {
            pageNum: this.pagination1.current,
            pageSize: this.pagination1.pageSize
          },
          { callNumber: this.callNumber }
        )
        .then(res => res.data)
        .then(data => {
          this.InfoList1 = data.content.list;
          this.pagination1.total = data.content.total;
        });
    },
    downloadBlob(content, fileName) {
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8"
      });
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      const filename = fileName;
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    //导出
    exportData() {
      this.$apis
        .getUdeskCallLogsFile({
          ...this.form
        })
        .then(res => {
          this.downloadBlob(res.data, `通话${Date.now()}.xls`);
        });
    },
    onChange(item) {
      let status = "1";
      if (item.status == "2") {
        status = "1";
      } else {
        status = "2";
      }
      let postData = {
        id: item.id,
        status: status
      };
      this.$apis
        .updateUdeskCallLogs(postData)
        .then(res => res.data)
        .then(data => {
          this.$message.success("更改成功");
          this.fetchData();
        });
    },
    addList() {
      if (this.newCallNumber) {
        this.$apis
          .insertUdeskDisableCall({ callNumber: this.newCallNumber })
          .then(res => res.data)
          .then(data => {
            this.$message.success("新增成功");
            this.pagination1.current = 1;
            this.fetchData1();
          });
      } else {
        this.$message.error("请输入手机号");
      }
    },
    pageChange(page) {
      this.pagination = page;
      this.fetchData();
    },
    pageChange1(page) {
      this.pagination1 = page;
      this.fetchData1();
    },
    onChange1(item) {
      let status = "1";
      if (item.status == "1") {
        status = "0";
      } else {
        status = "1";
      }
      let postData = {
        id: item.id,
        status: status
      };
      this.$apis
        .updateUdeskDisableCall(postData)
        .then(res => res.data)
        .then(data => {
          this.$message.success("更改成功");
          this.fetchData1();
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.page_ {
  padding-top: 50px;
  .top_ {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 6px 30px;
    z-index: 10;
    background-color: #fff;
    img {
      width: 100px;
      height: 31.5px;
    }
  }
  .con_ {
    padding: 0 50px;
    margin: 20px 0;
    .title_ {
      font-weight: bold;
      margin-top: 20px;
    }
    .flex_col {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span {
        white-space: nowrap;
      }
      .w_60 {
        width: 60%;
      }
    }
  }
}
.top-btn {
  margin-left: 20px;
}
</style>
